import React from 'react';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Dashboard from '@mui/icons-material/Dashboard';
import Dialpad from '@mui/icons-material/Dialpad';
import DirectionsRun from '@mui/icons-material/DirectionsRun';
import Diversity3 from '@mui/icons-material/Diversity3';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import Engineering from '@mui/icons-material/Engineering';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import Groups from '@mui/icons-material/Groups';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Person from '@mui/icons-material/Person';
import Place from '@mui/icons-material/Place';
import SportsEsports from '@mui/icons-material/SportsEsports';
import Work from '@mui/icons-material/Work';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTheme, type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Menu, useRedirect, useTranslate, type MenuProps } from 'react-admin';
import { useLocation } from 'react-router-dom';
import SubMenuItem from './SubMenuItem';
import { useTranslateMenuItems } from '../hooks';
import type { MenuItem as MenuItemInterface } from '../interfaces';

const dataItems: MenuItemInterface[] = [
  {
    name: 'Users',
    resource: 'user',
    icon: <Person />,
  },
  {
    name: 'Players',
    resource: 'player',
    icon: <DirectionsRun />,
  },
  {
    name: 'Teams',
    resource: 'team',
    icon: <Groups />,
  },
  {
    name: 'Formations',
    resource: 'formation',
    icon: <Dialpad />,
  },
  {
    name: 'Positions',
    resource: 'position',
    icon: <Place />,
  },
  {
    name: 'FIFAs',
    resource: 'fifa',
    icon: <SportsEsports />,
  },
];

const careerItems: MenuItemInterface[] = [
  {
    name: 'Career',
    resource: 'career',
    icon: <Work />,
  },
  {
    name: 'Managers',
    resource: 'manager',
    icon: <Engineering />,
  },
  {
    name: 'Trophies',
    resource: 'trophy',
    icon: <EmojiEvents />,
  },
  {
    name: 'Seasons',
    resource: 'season',
    icon: <CalendarMonth />,
  },
];

const otherItems: MenuItemInterface[] = [
  {
    name: 'Logs',
    resource: 'log',
    icon: <FolderIcon />,
  },
  {
    name: 'roadmap',
    resource: '',
    icon: <AddRoadIcon />,
    customPath: 'roadmap',
  },
];

const CustomMenu: React.FC<MenuProps> = (props) => {
  const [openData, setOpenData] = React.useState<boolean>(false);
  const [openCareer, setOpenCareer] = React.useState<boolean>(false);
  const [openOther, setOpenOther] = React.useState<boolean>(false);

  const translatedDataItems = useTranslateMenuItems(dataItems);
  const translatedCareerItems = useTranslateMenuItems(careerItems);
  const translatedOtherItems = useTranslateMenuItems(otherItems);

  const theme = useTheme();
  const redirect = useRedirect();
  const location = useLocation();
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Menu {...props}>
      <MenuItem
        onClick={() => redirect('/')}
        sx={{
          backgroundColor: location.pathname === '/' ? theme.palette.primary.contrastText : '',
          marginLeft: isSmall ? 0 : '2px',
        }}
      >
        <ListItemIcon
          sx={{
            color: location.pathname === '/' ? theme.palette.primary.main : '',
          }}
        >
          <Dashboard />
        </ListItemIcon>
        <ListItemText
          primary={translate('menuItems.dashboard')}
          sx={{
            color: location.pathname === '/' ? theme.palette.primary.main : '',
          }}
        />
      </MenuItem>
      <MenuItem
        onClick={() => setOpenData(!openData)}
        sx={{
          marginLeft: isSmall ? 0 : '2px',
        }}
      >
        <ListItemIcon>
          <Groups />
        </ListItemIcon>
        <ListItemText primary={translate('menuItems.data')} />
        {openData ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <SubMenuItem items={translatedDataItems} open={openData} />
      <MenuItem
        onClick={() => setOpenCareer(!openCareer)}
        sx={{
          marginLeft: isSmall ? 0 : '2px',
        }}
      >
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary={translate('menuItems.career')} />
        {openCareer ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <SubMenuItem items={translatedCareerItems} open={openCareer} />
      <MenuItem
        onClick={() => setOpenOther(!openOther)}
        sx={{
          marginLeft: isSmall ? 0 : '2px',
        }}
      >
        <ListItemIcon>
          <MiscellaneousServicesIcon />
        </ListItemIcon>
        <ListItemText primary={translate('menuItems.other')} />
        {openOther ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <SubMenuItem items={translatedOtherItems} open={openOther} />
      <MenuItem
        onClick={() => redirect('squad', 'list')}
        sx={{
          backgroundColor: location.pathname === '/squad' ? theme.palette.primary.contrastText : '',
          marginLeft: isSmall ? 0 : '2px',
        }}
      >
        <ListItemIcon
          sx={{
            color: location.pathname === '/squad' ? theme.palette.primary.main : '',
          }}
        >
          <Diversity3 />
        </ListItemIcon>
        <ListItemText
          primary={translate('menuItems.squads')}
          sx={{
            color: location.pathname === '/squad' ? theme.palette.primary.main : '',
          }}
        />
      </MenuItem>
    </Menu>
  );
};

export default CustomMenu;
