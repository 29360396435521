import React from 'react';
import { useTranslate } from 'react-admin';
import { SquadContext } from '../contexts/SquadContext';
import type { SquadContextProps, MenuItem } from '../interfaces';

/**
 * Hook that handles SquadContext.
 * @returns {SquadContextProps} Context state setters and values.
 * @example const { level, setLevel, fifa, setFifa } = useSquadContext();
 */
export const useSquadContext = (): SquadContextProps => {
  const context = React.useContext(SquadContext);
  if (!context) {
    throw new Error('Context error');
  }
  return context;
};

/**
 * Hook that translates sub menu items.
 * @param {MenuItem[]} items - Menu items to translate.
 * @returns {MenuItem[]} Translated menu items.
 * @example const translatedDataItems = useTranslateMenuItems(dataItems);
 */
export const useTranslateMenuItems = (items: MenuItem[]): MenuItem[] => {
  const translate = useTranslate();

  const returnTranslatedName = (item: MenuItem): string => {
    const { name, resource, customPath } = item;
    return customPath
      ? translate(`pages.${name}.title`)
      : translate(`resources.${resource}.name`, { _: '', smart_count: 2 });
  };

  const translatedItems = items.map((item) => {
    return {
      ...item,
      name: returnTranslatedName(item),
    };
  });

  return translatedItems;
};
