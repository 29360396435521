import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import type { Theme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useListFilterContext, useTranslate } from 'react-admin';
import type { IsFavouriteInputProps } from '../../interfaces';

const IsFavouriteInput: React.FC<IsFavouriteInputProps> = ({ key }) => {
  const [checked, setChecked] = React.useState<boolean>(false);
  const translate = useTranslate();
  const { setFilters, displayedFilters } = useListFilterContext();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
    const newFilterValues = {
      is_favourite: event.target.checked ? 1 : undefined,
    };
    setFilters(newFilterValues, displayedFilters);
  };

  return (
    <FormControlLabel
      label={translate('resources.player.fields.toggle_favourites')}
      key={key}
      control={<Switch checked={checked} onChange={handleChange} />}
      sx={{
        marginLeft: isSmall ? '10px' : 0,
        marginTop: isSmall ? '10px' : 0,
      }}
    />
  );
};

export default IsFavouriteInput;
