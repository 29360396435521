import React from 'react';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import type { SubMenuItemProps, MenuItem as MenuItemInterface } from '../interfaces';

const SubMenuItem: React.FC<SubMenuItemProps> = ({ open, items }) => {
  const theme = useTheme();
  const redirect = useRedirect();
  const location = useLocation();

  const handleRedirect = (item: MenuItemInterface): void => {
    const { resource, customPath } = item;
    if (customPath) redirect(`/${customPath}`);
    else redirect('list', resource);
  };

  const getMainColor = (item: MenuItemInterface): string => {
    const { resource, customPath } = item;
    const path = customPath ?? resource;
    return location.pathname === `/${path}` ? theme.palette.primary.main : '';
  };

  const getBackgroundColor = (item: MenuItemInterface): string => {
    const { resource, customPath } = item;
    const path = customPath ?? resource;
    return location.pathname === `/${path}` ? theme.palette.primary.contrastText : '';
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      {items.map((item: MenuItemInterface, index: number) => {
        return (
          <MenuItem
            onClick={() => handleRedirect(item)}
            sx={{
              backgroundColor: getBackgroundColor(item),
              marginLeft: '10px',
            }}
            key={index}
          >
            <ListItemIcon
              sx={{
                color: getMainColor(item),
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              sx={{
                color: getMainColor(item),
              }}
            />
          </MenuItem>
        );
      })}
    </Collapse>
  );
};

export default SubMenuItem;
